import Swiper from "swiper/dist/js/swiper.js";

document.addEventListener("DOMContentLoaded", function() {
    let swiper = new Swiper(".swiper-container", {
        slidesPerView: 1,
        spaceBetween: 10,
        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },
        lazy: {
          loadPrevNext: true
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
});

